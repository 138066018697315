import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import { Link } from 'gatsby';

import { useLunr } from 'react-lunr';
import Highlighter from 'react-highlight-words';
import lunrData from '../../public/static/search-index.json';

import Layout from '../components/layout/layout';

function SearchPage({ location }) {
  const [currentpageno, setCurrentpageno] = React.useState(1);
  const [query] = React.useState(new URLSearchParams(location.search).get('query') || '');
  const allSearchResults = useLunr(query.toLowerCase(), lunrData.index, lunrData.store)
    ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) || [];

  const [pagePaginationText, setPagePaginationText] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [results, setResults] = React.useState([]);

  /**
   * Takes in the body of a search result. Total characters to 130
   * searches the string, gets 65 characters from left and 65 from right.
   * clips the length at 130 and adds ...
   * @param {*} body string to filter the string from.
   */
  const generateSearResult = body => {
    const lowerCaseBody = body.toLowerCase();
    let queryAtIndex = null;

    if (lowerCaseBody.search(query.toLowerCase()) === -1) {
      const multiwords = query.toLowerCase().split(' ')
        .map(word => lowerCaseBody.search(word));
      const firstPositiveWord = query.toLowerCase().split(' ')[multiwords.findIndex(num => num > -1)];
      queryAtIndex = lowerCaseBody.search(firstPositiveWord);
    } else {
      queryAtIndex = lowerCaseBody.search(query.toLowerCase());
    }

    // Get the left start index and right end index with number 65
    let leftIndex = queryAtIndex - 85;
    let rightIndex = queryAtIndex + 85;

    if (leftIndex < 0) {
      rightIndex += (0 - leftIndex);
    }

    if (rightIndex < 0) {
      leftIndex += (0 - rightIndex);
    }

    return `${body.substring(leftIndex, rightIndex)} ...`;
  };

  const searchQueryList = () => {
    if (query.split().length > 0) {
      return [query].concat(query.split(' '));
    }
    return query;
  };

  const dateDisplay = dateString => new Date(dateString).toDateString();

  const setPagePagination = (_, pageno) => {
    const rowsPerPage = 10;
    let start = null;
    let end = null;
    const of = allSearchResults.length;

    // Start number logic
    if (of === 0) {
      start = 0;
      end = 0;
    }

    if (pageno === 1 && of > 0) {
      start = 1;
      if (allSearchResults.length < rowsPerPage) {
        end = allSearchResults.length;
      } else {
        end = rowsPerPage;
      }
    }

    if (pageno > 1) {
      start = rowsPerPage * (pageno - 1) + 1;
      end = rowsPerPage * pageno;
      if (allSearchResults.length < end) {
        end = allSearchResults.length;
      }
    }

    setPagePaginationText(`Showing ${start}-${end} of ${of}`);
  };

  const onPageChange = (_, newPageNo) => {
    const newResult = new Array(10)
      .fill(null)
      .map((__, index) => allSearchResults[((newPageNo - 1) * 10) + index])
      .filter(item => item !== undefined);
    setResults(newResult);
    setPagePagination(newResult.length, newPageNo);
    setCurrentpageno(newPageNo);
  };

  React.useEffect(() => {
    if (!query || (query.length && query.length < 2)) {
      setResults(null);
      setLoading(false);
      return;
    }

    if (allSearchResults.length !== 0) {
      const newResults = new Array(10)
        .fill(null)
        .map((_, index) => allSearchResults[index])
        .filter(item => item !== undefined);
      setResults(newResults);
      setPagePagination(newResults.length, currentpageno);
    } else {
      setResults([]);
      setPagePagination(0);
    }
    const timer = window.setInterval(() => {
      setLoading(false);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  return (
    <Layout
      location={location}
      pageName={query}
    >
      <Container sx={{ pt: 3, pb: 2 }}>

        {/* Query Length is greater than 3 */}
        {query && query.length >= 2 && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h2'>
                Search Results for
                &quot;
                {query}
                &quot;
                &nbsp;
                <Typography
                  variant='body1'
                  sx={{
                    display: {
                      xs: 'block',
                      md: 'inline'
                    },
                    pt: {
                      xs: 1
                    }
                  }}
                >
                  &#40;
                  {allSearchResults.length}
                  &nbsp;
                  Results
                  &#41;
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ backgroundColor: '#E95525', height: 5, mt: 1, mb: 2 }} />
            </Grid>
          </Grid>
        )}

        {loading && (
          <Box sx={{ padding: '20px 0 50px' }}>
            {[1, 2].map(() => (
              <Box sx={{ mb: 1, width: '80%' }}>
                <Skeleton variant='text' height={30} width='100%' />
                <Skeleton variant='text' height={60} width='100%' />
              </Box>
            ))}
          </Box>
        )}

        {/* Search Results */}
        {!loading && results && results.length > 0 && query && query.length >= 2 && (
          <Grid container spacing={2} sx={{ padding: '20px 0 50px' }}>

            {/* Search Results */}
            <Grid item xs={12}>
              <Box>
                <Typography
                  variant='cardcategory'
                  sx={{ mr: 1 }}
                >
                  {pagePaginationText}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ width: '100%', pt: 0 }}>
                {results.map(item => (
                  <React.Fragment key={item.id}>
                    <ListItem alignItems='flex-start' sx={{ mt: 1, pl: 0 }}>
                      <Link to={item.slug} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant='cardcategory' sx={{ textTransform: 'uppercase' }}>{item.category}</Typography>
                        <ListItemText
                          sx={{ width: '80%', mt: 0 }}
                          primary={<Typography variant='h4'>{item.title}</Typography>}
                          secondary={(
                            <Typography
                              component='div'
                              variant='body1'
                              color='text.primary'
                            >
                              {dateDisplay(item.updatedAt)}
                              &nbsp;
                              -
                              &nbsp;
                              <Highlighter
                                searchWords={searchQueryList()}
                                textToHighlight={generateSearResult(item.body)}
                              />
                            </Typography>
                          )}
                        />
                      </Link>
                    </ListItem>
                    <Divider variant='inset' component='li' sx={{ width: '80%', ml: '12px' }} />
                  </React.Fragment>
                ))}
              </List>
            </Grid>

            {/* Pagination */}
            {!loading && results && results.length > 0 && (
              <Grid item xs={12}>
                <Pagination
                  count={Math.ceil(allSearchResults.length / 10)}
                  currentpageno={currentpageno}
                  onChange={onPageChange}
                  variant='outlined'
                />
              </Grid>
            )}
          </Grid>
        )}

        {!loading && query && query.length < 2 && (
          <Box>
            <Typography variant='h2'>Please enter search query greater or equal to 2 characters</Typography>
          </Box>
        )}

        {/* No Results found */}
        {!loading && results && results.length === 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant='h2'>No results found</Typography>
          </Box>
        )}

      </Container>
    </Layout>
  );
}

SearchPage.propTypes = {
  location: PropTypes.shape.isRequired
};

export default SearchPage;
